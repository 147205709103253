class Observer {

  constructor() {
    this.map = new Map()
    this.observer = new IntersectionObserver(entries => this.onChange(entries))
  }

  onChange(entries) {
    entries.forEach(entry => {
      const fn = this.map.get(entry.target)
      fn(entry)
    })
  }

  observe(dom, fn) {
    this.map.set(dom, fn)
    this.observer.observe(dom)
  }
}

export default Observer
