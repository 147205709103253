import {
  $$,
  $,
  first,
  isUndef,
  last,
  noop,
  on,
  px,
  rmProp,
  roundX,
  toInt,
  useDrag
} from '../utils'

class RangeInput {

  constructor(selector, options = {}) {
    const dom = $(selector)
    this.change = options.change || noop
    this.max = options.max
    this.dom = dom
    this.input = $('[data-input]', dom)
    this.emptyBar = $('[data-empty-bar]', dom)
    this.btn = $('[data-btn]', dom)
    this.labels = $$('[data-label-bar] > div', dom)
    this.left = 0
    this.deltaX = 0
    this.nextLeft = 0
    this.boxWidth = this.input.offsetWidth
    this.setScaleRows()
    this.addEvents()
    this.setEmptyBarLeft(options.value || 0)
  }

  setScaleRows() {
    const start = this.labels[0].offsetLeft
    const firstRow = { pos: 0, value: 0 }
    const rows = this.labels.map(el => {
      return {
        pos: roundX(el.offsetLeft + (el.offsetWidth / 2) - start),
        value: toInt(el.dataset.value)
      }
    })
    if (isUndef(this.max)) {
      this.max = last(rows).value + first(rows).value
    }
    const lastRow = { pos: this.boxWidth, value: this.max }
    this.scaleRows = [firstRow, ...rows, lastRow]
  }

  scaleBtn(scale) {
    if (scale) {
      this.btn.style.transform = `scale(${scale})`
    }
    else {
      rmProp(this.btn.style, 'transform')
    }
  }

  adjustEmptyBar() {
    const { boxWidth } = this
    let nextLeft = this.left + this.deltaX
    if (nextLeft < 0) {
      nextLeft = 0
    }
    if (nextLeft > boxWidth) {
      nextLeft = boxWidth
    }
    this.nextLeft = nextLeft
    this.emptyBar.style.left = px(nextLeft)
    const value = this.posToValue(nextLeft)
    if (this._value !== value) {
      this.change({ value })
    }
    this._value = value
  }

  posToValue(pos) {
    const rows = this.scaleRows
    for (let i = 1; i < rows.length; i++) {
      const prevRow = rows[i - 1]
      const row = rows[i]
      if (pos === prevRow.pos) {
        return prevRow.value
      }
      if (pos === row.pos) {
        return row.value
      }
      if (pos < row.pos) {
        const ratio = (pos - prevRow.pos) / (row.pos - prevRow.pos)
        const value = (row.value - prevRow.value) * ratio
        return roundX(prevRow.value + value)
      }
    }
    return last(rows).value
  }

  valueToPos(value) {
    const rows = this.scaleRows
    for (let i = 1; i < rows.length; i++) {
      const prevRow = rows[i - 1]
      const row = rows[i]
      if (value === prevRow.value) {
        return prevRow.pos
      }
      if (value === row.value) {
        return row.pos
      }
      if (value < row.value) {
        const ratio = (value - prevRow.value) / (row.value - prevRow.value)
        const distance = (row.pos - prevRow.pos) * ratio
        return roundX(prevRow.pos + distance)
      }
    }
    return last(rows).pos
  }

  raf(fn) {
    this.rafId = window.requestAnimationFrame(() => {
      fn.call(this)
      this.raf(fn)
    })
  }

  stopRaf() {
    window.cancelAnimationFrame(this.rafId)
  }

  setEmptyBarLeft(value) {
    const pos = this.valueToPos(value)
    this.left = pos
    this.emptyBar.style.left = px(pos)
    this.change({ value })
  }

  onLabelClick = event => {
    const value = toInt(event.target.dataset.value)
    this.setEmptyBarLeft(value)
  }

  addEvents() {
    const { btn } = this
    const down = () => {
      this.raf(this.adjustEmptyBar)
      this.scaleBtn(1.2)
    }
    const up = () => {
      this.stopRaf()
      this.left = this.nextLeft
      this.nextLeft = 0
      this.deltaX = 0
      this.scaleBtn()
    }
    this.dragSubscription = useDrag(btn, { down, up })
      .subscribe(value => {
        this.deltaX = value.deltaX
      })
    this.offLabels = this.labels.map(el => {
      return on(el, 'click', this.onLabelClick)
    })
  }

  destroy() {
    this.dragSubscription.unsubscribe()
    this.offLabels.forEach(off => off())
  }
}

export default RangeInput
