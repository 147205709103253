import './styles/main.scss'
import './styles/pages/_xdelivery.scss'
import './scripts/common'
import './scripts/nav'
import './scripts/footer'
import Form from './models/Form'
import RangeInput from './models/RangeInput'
import Elevator from './models/Elevator'
import { $, $$, axios, comma, get, polyfill, roundX } from './utils'
import { PRICING_LVS, getPricingLvs, toPiece } from '@landing/official-common/consts/xdelivery'

const dom = $('#pricing-table')
const trs = $$('table > tbody > tr', dom)
const resBar = $('[data-res]', dom)

const change = res => {
  const { value } = res
  const rows = getPricingLvs(value)

  let totalFee = 0

  trs.forEach((tr, i) => {
    const row = rows[i]
    const tds = $$('td', tr)
    if (row) {
      totalFee += row.fee
      tds[1].textContent = toPiece(row.count) + ' X'
      tds[2].textContent = comma(row.cost) + ' 元 ='
      tds[3].textContent = comma(roundX(row.fee)) + ' 元'
    }
    else {
      tds[1].textContent = '0 X'
      tds[2].textContent = PRICING_LVS[i].cost + ' 元 ='
      tds[3].textContent = '0'
    }
  })
  resBar.textContent = ` ${toPiece(value)} 包裹，運費為 ${comma(Math.floor(totalFee)) + ' 元'}`
}

new RangeInput('#range-input', {
  value: 150,
  max: 700,
  change
})

polyfill()
  .then(() => {
    new Elevator('#xdelivery-list-menu', { safeDistance: 0 })
  })

const form = new Form('#form', {
  submit(fields) {
    axios.head('/csrf')
      .then(res => {
        const csrfToken = res.headers['x-csrf-token']
        const data = {
          authenticity_token: csrfToken,
          apply: fields
        }
        axios.post('/applies/xdelivery.json', data)
          .then(() => {
            const wrapper = $('#contact-xdelivery')
            const dom = $('#form')
            const successMsg = document.createElement('div')
            successMsg.className = 'xdelivery-form__success text-center'
            successMsg.innerHTML = `
              您好，感謝您的申請！<br>
              您的資訊將由一頁商店審核小組進行確認，<br>
              審核流程約需 3-5 個工作天 (不含例假日)，<br>
              如通過申請，我們會寄送 e-mail 通知您，<br>
              如未通過申請，恕不另行通知，<br>
              有任何問題，歡迎您於上班時間洽詢線上客服，謝謝！
            `
            wrapper.removeChild(dom)
            wrapper.appendChild(successMsg)
          })
          .catch(err => {
            const fieldErrs = get(err, 'response.data.errors')
            if (fieldErrs) {
              form.setFieldErrs(fieldErrs)
            }
          })
      })
  }
})
