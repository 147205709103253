import Observer from '@landing/official-desktop/models/Observer'
import {
  $,
  $$,
  compact,
  debounceTime,
  fromEvent,
  getDomPos,
  isDef,
  keyBy,
  px,
  rmProp,
  throttleTime
} from '@landing/official-desktop/utils'

class Elevator {

  constructor(selector, options = {}) {
    this.menu = $(selector)
    this.safeDistance = isDef(options.safeDistance) ? options.safeDistance : 1000
    this.observer = new Observer()
    this.initMenu()
  }

  initMenu() {
    const { menu } = this
    this.menuPos = getDomPos(menu)
    this.setMenuStyle()

    fromEvent(window, 'scroll')
      .pipe(throttleTime(30))
      .subscribe(() => this.setMenuStyle())

    fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.clearStyle()
        this.menuPos = getDomPos(menu)
        this.setMenuStyle()
      })

    this.initMenuItems()
  }

  clearStyle() {
    const { style } = this.menu
    rmProp(style, 'position')
    rmProp(style, 'top')
    rmProp(style, 'left')
  }

  setMenuStyle() {
    const { menu, safeDistance } = this
    const navHeight = 60
    const marginTop = 14 + navHeight
    const pos = this.menuPos

    // tested solution: feeds scrollingElement for ipad
    const { scrollTop, scrollHeight } = document.scrollingElement || document.documentElement

    if ((scrollTop + safeDistance) > scrollHeight) {
      this.clearStyle()
      return
    }
    if (scrollTop > (pos.y - marginTop)) {
      menu.style.position = 'fixed'
      menu.style.top = px(marginTop)
      menu.style.left = px(pos.x)
    }
    else {
      this.clearStyle()
    }
  }

  initMenuItems() {
    const anchors = $$('a', this.menu)
    const anchorMap = keyBy(anchors, a => a.getAttribute('href'))
    const selectors = Object.keys(anchorMap)
    const panes = compact(selectors.map(s => $(s)))
    const { observer } = this
    panes.forEach(el => {
      observer.observe(el, entry => {
        if (entry.isIntersecting) {
          anchors.forEach(a => a.classList.remove('js-active'))
          const target = anchorMap['#' + entry.target.id]
          if (target) {
            target.classList.add('js-active')
          }
        }
      })
    })
  }
}

export default Elevator
