// 統一編號檢查
// https://gist.github.com/kaochenlong/1889703
// 測資
// https://sheethub.com/data.gcis.nat.gov.tw/統一編號列表
const mutiples = [1, 2, 1, 2, 1, 2, 4, 1]
const toInt = num => parseInt(num, 10)

export default function isValidCompanyCode(code) {

  if (! /^\d{8}$/.test(code)) {
    return false
  }

  const nums = code.split('')
    .map(num => toInt(num))

  const sum = nums.reduce((s, num, i) => {
    const [tens, units] = String(num * mutiples[i])
      .padStart(2, '0')
    return s + toInt(units) + parseInt(tens)
  }, 0)

  if (sum % 10 === 0) {
    return true
  }
  if ((nums[6] === 7) && ((sum + 1) % 10 === 0)) {
    return true
  }
  return false
}
