import { $, $$, fromEvent, getParent, toInt, noop } from '@landing/official-desktop/utils'
import hasValue from '@landing/common/utils/hasValue'
import isValidEmail from '@landing/common/utils/isValidEmail'
import isValidCompanyCode from '@landing/common/utils/isValidCompanyCode'
import isValidUrl from '@landing/common/utils/isValidUrl'

const required = v => hasValue(v)

const RULES = {
  required,
  maxlength: (v, length) => v.length <= length,
  email: v => isValidEmail(v),
  taxidno: v => isValidCompanyCode(v),
  num: v => Number.isInteger(toInt(v)),
  gt: (v, num) => toInt(v) > toInt(num),
  url: v => isValidUrl(v)
}

class Form {

  constructor(selector, options = {}) {
    const form = $(selector)
    this.controls = $$('input, textarea, select', form)
    this.form = form
    this.rows = []
    this.submit = options.submit || noop
    this.addEvents()
  }

  styleControl(dom, errs) {

    const div = getParent(dom, 'div')
    const existedErrBox = $('[data-err-box]', div)

    if (existedErrBox) {
      div.removeChild(existedErrBox)
    }
    if (errs.length > 0) {
      div.classList.add('form__danger')
      const errBox = document.createElement('div')
      errBox.dataset.errBox = true
      errBox.innerHTML = errs.filter(row => hasValue(row.msg))
        .map(row => {
          return `<div class="form__hint form__hint--danger">${row.msg}</div>`
        })
        .join('')
      div.appendChild(errBox)
    }
    else {
      div.classList.remove('form__danger')
    }
  }

  validate() {
    const rows = this.controls.map(dom => {
      const { value, dataset } = dom
      const errs = Object.keys(dataset)
        .map(prop => {
          const msg = dataset[prop]
          const [key, ...args] = prop.split('-')
          const fn = RULES[key]
          const matched = (key === 'required') || hasValue(value)
          if (fn && matched) {
            return { key, fn, args, msg }
          }
        })
        .filter(row => row)
        .map(row => {
          const { args, fn, key, msg } = row
          const success = fn(value, ...args)
          return { key, success, msg }
        })
        .filter(row => (! row.success))
      return { dom, errs }
    })

    rows.forEach(row => {
      this.styleControl(row.dom, row.errs)
    })

    this.rows = rows

    return rows.every(row => row.errs.length === 0)
  }

  scrollToFirstErr() {
    const row = this.rows.find(row => row.errs.length > 0)
    if (row) {
      row.dom.parentNode.scrollIntoView()
    }
  }

  setFieldErrs(fieldErrs) {
    const map = this.controls.reduce((obj, elem) => {
      obj[elem.name] = elem
      return obj
    }, {})

    const rows = Object.keys(fieldErrs)
      .filter(key => key in map)
      .map(key => {
        const dom = map[key]
        const errs = fieldErrs[key].map(msg => ({ msg }))
        this.styleControl(dom, errs)
        return { dom, errs }
      })
    this.rows = rows
    this.scrollToFirstErr()
  }

  addEvents() {
    const { form } = this
    fromEvent(form, 'submit')
      .subscribe(event => {
        event.preventDefault()
        const success = this.validate()
        if (! success) {
          return this.scrollToFirstErr()
        }
        const data = this.controls.reduce((obj, elem) => {
          const { name } = elem
          if (name) {
            obj[name] = elem.value
          }
          return obj
        }, {})
        this.submit(data)
      })
  }
}

export default Form
